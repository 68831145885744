<template>
  <SingleComponent
    :loading="loading"
    :mode="mode"
    @add-button="addButton"
    @edit-button="editButton"
  >
    <v-text-field
      v-model="input.timeBegin"
      v-mask="'##:##'"
      label="Начало"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-text-field
      v-model="input.timeEnd"
      v-mask="'##:##'"
      label="Конец"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-text-field
      v-model="input.breakAmountPlan"
      label="Кол-во перерывов"
      :rules="[$validate.required, $validate.float]"
    >
    </v-text-field>
    <v-text-field
      v-model="input.breakTimePlan"
      label="Длина перерыва"
      :rules="[$validate.required, $validate.float]"
    >
    </v-text-field>
    <v-select
      v-model="input.departmentId"
      label="Филиал"
      :items="DEPARTMENT.cityDepartment"
      item-value="id"
      item-text="name"
      :rules="[$validate.required]"
    ></v-select>
    <v-select
      v-model="input.roleId"
      label="Права"
      :items="ROLE.allRoles"
      item-text="name"
      item-value="id"
      :rules="[$validate.required]"
    >
    </v-select>
  </SingleComponent>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleComponent from "@/components/SingleComponent";
import timetable from "@/model/timetable";
export default {
  components: { SingleComponent },
  data() {
    return {
      loading: true,
      mode: "Добавить",
      input: {},
    };
  },
  computed: {
    ...mapGetters({
      ROLE: "Role/STATE",
      DEPARTMENT: "Department/STATE",
    }),
  },
  async created() {
    let body = {};
    await this.getPowers();
    await this.getDepartment(this.$root.city);
    if (this.$route.params.id) {
      this.mode = "Сохранить";
      body = await this.getTimetable(this.$route.params.id);
    }
    let model = new timetable();
    this.input = model.setInput(this.mode, body);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getTimetable: "Timetable/GET_TIMETABLE_ID",
      addTimetable: "Timetable/ADD_TIMETABLE",
      editTimetable: "Timetable/EDIT_TIMETABLE",
      getPowers: "Role/GET_ALL_ROLES",
      getDepartment: "Department/GET_DEPARTMENT",
    }),
    async addButton() {
      this.loading = true;
      let response = await this.addTimetable(this.input);
      if (response.type === "success") {
        this.$router.push("/timetable");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.text,
        });
      }
      this.loading = false;
    },
    async editButton() {
      this.loading = true;
      let response = await this.editTimetable(this.input);
      if (response.type === "success") {
        this.$router.push("/timetable");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.text,
        });
      }
      this.loading = false;
    },
  },
};
</script>
